<template>
    <div :class="inputContainerClass">
         <v-switch
class="mt-0"
      v-model="content"
      @change="handleInput"
      :label="labelSwitch"
      :true-value="1"
      :false-value="0"
    ></v-switch>
    </div>
</template>
<script>
import Commons from "./Commons.vue";
export default {
    extends: Commons,
    props: {
        'parametro': {
            type: String,
            required: true,
        },
        'init-value':{
            type: undefined,
        },
        'data': {
            type: String,
        },
        'true': {
            type: String,
            default: 'Sì',
        },
        'false': {
            type: String,
            default: 'No',
        },
        'compact': {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        labelSwitch: function () {
			if (this.compact) return '';
            return (+this.content) ? this.true : this.false;
        },
    },
};
</script>
<style lang="scss">
</style>
