<template>
    <div :class="inputContainerClass">
         <v-text-field
            v-model="content"
            @input="handleInput"
            :max="max"
            :min="min"
          ></v-text-field>
    </div>
</template>
<script>
import Commons from "./Commons.vue";
export default {
    extends: Commons,
    props: {
        'parametro': {
            type: String,
            required: true,
        },
        'init-value':{
            type: undefined,
        },
        'data': {
            type: String,
        },
        'min': {
            type: Number,
            default: 0,
        },
        'max': {
            type: Number,
            default: 10,
        },
    },
};
</script>
<style lang="scss">
</style>
