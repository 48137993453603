<template>
    <div :class="inputContainerClass" class="msp-misurazione-slider">
        <v-slider
            v-model="content"
            thumb-label
            :hide-details="true"
            @change="handleInput"
            :max="max"
            :min="min"
            :step="step"
            :thumb-color="colorFromValue"
        ></v-slider>
        <div v-if="hasLabels" class="msp-misurazione-slider__labels">
            <div v-for="label in labels" :key="label" class="msp-misurazione-slider__label">
                {{label}}
            </div>


        </div>
    </div>
</template>
<script>
import Commons from "./Commons.vue";
export default {
    extends: Commons,
    props: {
        'parametro': {
            type: String,
            required: true,
        },
        'init-value':{
            type: undefined,
        },
        'data': {
            type: String,
        },
        'min': {
            type: Number,
            default: 0,
        },
        'max': {
            type: Number,
            default: 10,
        },
        'step': {
            type: Number,
            default: 1,
        },
        'labels': {
            type: Array,
            default: () => [
                'basso',
                'normale',
                'top',
            ],
        },
    },
    data() {
        return {
        }
    },
    computed: {
        hasLabels: function () {
            return this.labels && this.labels.length;
        },
        colorFromValue: function () {
            if (!this.isChanged) {
                return "#fff6 !important";
            }
            return "var(--col-grigio-scuro)";
        },
    },
    mounted: function () {
        this.resetContent();
    },
    watch: {
        isChanged: function (nv) {
            if (!nv) {
                this.resetContent();
            }
        },
    },
    methods: {
        resetContent: function () {
            this.content = (this.min + this.max) /2
        }
    },
};
</script>
<style  lang="scss">
.msp-misurazione-slider {
    --size: 20px;
    .v-slider {
        .v-slider__track-container {
            height: var(--size);
            border-radius: 10px;
            overflow: hidden;
            background: linear-gradient(90deg, #c00, #0c0);
        }
        .v-slider__thumb {
            width: var(--size);
            height: var(--size);
            left: calc(-0.5 * var(--size));
        }
        .v-slider__track-fill {
            background: #fff0 !important;
        }
        .v-slider__track-background {
            background: #fff0 !important;
        }
        .v-slider__thumb {
            background: #fff !important;
        }
        .v-slider__thumb-label {
            // background-color: red !important;
        }
    }

    &__labels {
        display: flex;
        justify-content: stretch;
        text-align: center;
    }
    &__label {
        flex: 1;
        &:first-child {
            text-align: left;
        }
        &:last-child {
            text-align: right;
        }
    }
}
</style>
