<template>
    <div class="msp-misurazione-radio" :class="inputContainerClass">
        <v-switch
            class="mt-0"
            v-model="selected"
            :label="labelSwitch"
            :true-value="1"
            :false-value="0"
            @change="toggleRadio"
        ></v-switch>
        <div v-if="selected">
            <v-radio-group
                class="mt-0"
                v-model="content"
                @change="handleInput"
            >
                <v-radio v-for="option in options"
                    :key="option.value"
                    :label="option.label"
                    :color="color(option)"
                    :value="option.value"
                ><template v-if="option.descrizione" v-slot:label><strong>{{option.label}}</strong><br>{{option.descrizione}}</template></v-radio>
            </v-radio-group>
        </div>
    </div>
</template>
<script>
import Commons from "./Commons.vue";
export default {
    extends: Commons,
    props: {
        'parametro': {
            type: String,
            required: true,
        },
        'data': {
            type: String,
        },
        'true': {
            type: String,
            default: 'Sì',
        },
        'false': {
            type: String,
            default: 'No',
        },
        'compact': {
            type: Boolean,
            default: false,
        },
        'options': {
            type: Array,
            default: ()=>[]
        },
    },
    watch: {
        content: function (nv) {
            if (nv) {
                this.selected = 1; 
            } else {
                this.selected = false;
            }
        },
    },
    data: ()=> {
        return {
            selected: false,
        }
    },
    computed: {
        labelSwitch: function () {
			if (this.compact) return '';
            return (+this.content) ? this.true : this.false;
        },
    },
    mounted: function (){
        if (this.content) {
            this.selected = 1; 
        } else {
            this.selected = false;
        }
    },
    methods: {
        toggleRadio: function (val) {
            if (!val) {
                this.content = 0;
                this.handleInput(this.content);
            } else {
                this.content = this.options[0].value;
                this.handleInput(this.content);
            }
        },
        color: function (option) {
            if (!option.color) return "primary";
            return option.color;
        },
    },
};
</script>
<style lang="scss">
.msp-misurazione-radio {
    .v-radio {
        .v-label {
        display: block;
    }
}
}
</style>
