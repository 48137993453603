<script>
import _funzioni from "@src/js/msp/funzioni.js";

export default {
  props: {
    label: {
      type: String,
      default: ""
    },
    hideLabel: {
        type: Boolean,
        default: false,
    },
    last: {},
    id: {
      default: Date.now()
    },
    type: {
      type: String,
      default: "text"
    },
    classes: {
      type: Object
    },
    placeHolder: {
      type: String
    },
    rules: {
      type: String
    },
    name: {
      type: String
    },
    inputContainerClass: {
      type: String
    },
    inputClass: {
      type: String
    },
    dataSource: {
      type: Array
    },
    dataTextField: {
      type: String
    },
    dataValueField: {
      type: String
    },
    value: {},
    column: {
        type: String
    }
  },
  watch: {
    value: {
        handler(val) {
            this.content = val;
            if (val) {
                this.isChanged = true;
            } else {
                this.isChanged = false;
            }
        },
        deep: true
    }
  },
  data() {
      return {
          isChanged: false,
          content: null,
      };
  },
  methods: {
      formatLast: function (last) {
          if (!last) return "";
          const date = _funzioni.formatDate(last.date);
          const label = (this.label) ? this.label : "";
          return `${last.value} ${label} <span class="data">(${date})</span>`;
      },
      handleInput(value) {
          this.isChanged = true;
          const payload = {
              value: value,
              column: this.column,
          }
          this.$emit("input", payload);
      }
  }
};
</script>
